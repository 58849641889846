import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public noticount;
  public notificationdata = [];
  showLoadMore = false;
  page = 0;
  public loading = false;
  public currentUser;
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /*shownotification()
  {
    console.log('ssss');
  }*/
  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser!=null)
    {
      this.notifcations(this.page);
    }
    
   // this.notifcations(this.page);
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
  }

  notifcations(page=0){
    this.page = page;
    
    this.loading = true;
    this._notificationsService.notifications(page).subscribe(
      response => {
       
       
        this.noticount = response.count;
        if(this.page == 0){
        this.notificationdata = response.data;
      }else{
        this.notificationdata = this.notificationdata.concat(response.data);
      }
      this.loading = false;
        let current_count = response.data.length;
        if(current_count >= 20){
          this.showLoadMore = true;
        }
        else{
          this.showLoadMore = false;
        }
       
       
      });
  }

  clear_notifications()
  {
    this._notificationsService.clear_notifications().subscribe(
      response => {
        this.notifcations(this.page);
      });
  }
  showMoreNotifications(){
    this.page += 1; 
    this.loading = true;
    this.notifcations(this.page);
  }
}
