import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ContentService {
  // Public
  public apiData: any;
  public onApiDataChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onApiDataChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getApiData()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get Api Data
   */
  getApiData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/dashboard-data').subscribe((response: any) => {
        this.apiData = response;
        this.onApiDataChanged.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }

  


  start_call(booking_id: string) {
    const logintoken = localStorage.getItem('logintoken');
    return this._httpClient
     .post<any>(`${environment.apiUrl}/start-call`, {booking_id},
     {
      headers: {
        Authorization: 'Bearer ' + logintoken
      }
    });
  }

  college_end_call(duration: string) {
    const logintoken = localStorage.getItem('logintoken');
    return this._httpClient
     .post<any>(`${environment.apiUrl}/college-end-call`, {duration},
     {
      headers: {
        Authorization: 'Bearer ' + logintoken
      }
    });
  }

  call_complete_notification(booking_id: string) {
    const logintoken = localStorage.getItem('logintoken');
    return this._httpClient
     .post<any>(`${environment.apiUrl}/call-complete-notification`, {booking_id},
     {
      headers: {
        Authorization: 'Bearer ' + logintoken
      }
    });
  }
  
}
