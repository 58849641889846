import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
//declare var sendbirdConnect: any;
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/login`, { email, password })
      .pipe(
        map(user => {
         
          // login successful if there's a jwt token in the response
          if (user) {
         //console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
           
            localStorage.setItem('currentUser', JSON.stringify(user.data))
            localStorage.setItem('preference_data', JSON.stringify(user.preference_data))
          //  console.log(localStorage.setItem('currentUser', JSON.stringify(user)));
            // Display welcome toast!
            /*setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                  user.data.first_name +
                  ' user to Admintearly. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcomes, ' + user.data.first_name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);*/

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  forgotpassword(email: string) {
    return this._http
     .post<any>(`${environment.apiUrl}/forgot-password`, { email });
  }

  register(profile_image,user_type:string,first_name:string,last_name:string,email:string,dob:string,gender:string,password:string) {
    var newformdata = new FormData();
    newformdata.append('web_profile_image',profile_image);
    newformdata.append('user_type',user_type);
    newformdata.append('first_name',first_name);
    newformdata.append('last_name',last_name);
    newformdata.append('email',email);
    newformdata.append('gender',gender);
    newformdata.append('dob',dob);
    newformdata.append('password',password);
    return this._http
     .post<any>(`${environment.apiUrl}/register`,newformdata);
  }
  registerwithoutprofile(user_type:string,first_name:string,last_name:string,email:string,dob:string,gender:string,password:string) {
    var newformdata = new FormData();
    newformdata.append('user_type',user_type);
    newformdata.append('first_name',first_name);
    newformdata.append('last_name',last_name);
    newformdata.append('email',email);
    newformdata.append('gender',gender);
    newformdata.append('dob',dob);
    newformdata.append('password',password);
    return this._http
     .post<any>(`${environment.apiUrl}/register`,newformdata);
  }

  register_high(profile_image,email_code:string,user_type:string,first_name:string,last_name:string,email:string,dob:string,gender:string,password:string,skip:string) {
    var newformdata = new FormData();
    newformdata.append('web_profile_image',profile_image);
    newformdata.append('email_code',email_code);
    newformdata.append('user_type',user_type);
    newformdata.append('first_name',first_name);
    newformdata.append('last_name',last_name);
    newformdata.append('email',email);
    newformdata.append('gender',gender);
    newformdata.append('dob',dob);
    newformdata.append('password',password);
    newformdata.append('skip',skip);
    return this._http
     .post<any>(`${environment.apiUrl}/register`, newformdata);
  }

  register_high_withoutimg(email_code:string,user_type:string,first_name:string,last_name:string,email:string,dob:string,gender:string,password:string,skip:string) {
    var newformdata = new FormData();
    newformdata.append('email_code',email_code);
    newformdata.append('user_type',user_type);
    newformdata.append('first_name',first_name);
    newformdata.append('last_name',last_name);
    newformdata.append('email',email);
    newformdata.append('gender',gender);
    newformdata.append('dob',dob);
    newformdata.append('password',password);
    newformdata.append('skip',skip);
    return this._http
     .post<any>(`${environment.apiUrl}/register`, newformdata);
  }

  school_preference(tokenvalue:string, home_state:string,interests:string,possible_majors:string,colleges_interested_in:string,is_applied_college:string,like_to_connect_with:string,college_applied_to:string,timezone:string) {
    return this._http
     .post<any>(`${environment.apiUrl}/school-preference`, { home_state,interests,possible_majors,colleges_interested_in,is_applied_college,like_to_connect_with,college_applied_to,timezone},{
      headers: {
        Authorization: 'Bearer ' + tokenvalue
      }
    });
  }

  college_preference(tokenvalue:string, home_state:string,interests:string,major:string,colleges_in:string,payment_method:string,payment_method_id:string,timezone:string,class_year) {
    return this._http
     .post<any>(`${environment.apiUrl}/college-preference`, { home_state,interests,major,colleges_in,payment_method,payment_method_id,timezone,class_year},{
      headers: {
        Authorization: 'Bearer ' + tokenvalue
      }
    });
  }

  email_verification(email:string) {
    return this._http
     .post<any>(`${environment.apiUrl}/email-verification`, { email});
  }

 
  getAlljsonfile(): Observable<any> {
    return this._http.get(`${environment.apiUrl}/get-files`);
}

reset_password(token: string,email: string, password: string) {
  return this._http
   .post<any>(`${environment.apiUrl}/reset-password`, { token, email,password });
}

 send_birdapi(user_id:string,nickname:string,profile_url) {
  const tokenvalue='f77faae72455ffa9461a9683c05b5c6e35c9bfe2';
  return this._http
   .post<any>(`https://api-D7D388B5-A9C6-44DE-85C5-472B5917E2A8.sendbird.com/v3/users`, { user_id,nickname,profile_url},{
    headers: {
      'Api-Token': tokenvalue
    }
  });
}

 /* Dev send_birdapi(user_id:string,nickname:string,profile_url) {
  const tokenvalue='68bd6c59cc3a75dcf14d464f379645e80837fa00';
  return this._http
   .post<any>(`https://api-4CB85EC7-73A6-492F-A7A9-A10F377A2C30.sendbird.com/v3/users`, { user_id,nickname,profile_url},{
    headers: {
      'Api-Token': tokenvalue
    }
  });
}*/


  /**
   * User logout
   *
   */
  logout() {
    //sendbirdConnect.deauthenticate1();
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('usertype');
    localStorage.removeItem('logintoken');
    localStorage.removeItem('preference_data');
    localStorage.removeItem('booking_price');
    localStorage.removeItem('data_slots');
    localStorage.removeItem('redirect_to_login');
    localStorage.removeItem('skip_status');
    // notify
    this.currentUserSubject.next(null);
  }
}
