<!-- Create a template reference to the outlet directive with the template variable assignment #outlet="outlet".
With this reference to the outlet directive, we can get the information of when the router outlet is active to trigger our animations. -->
<!--<button (click)='startTimer()'>Start Timer</button>-->
<button (click)='pauseTimer()' class="d-none" id="pausetimer"></button>
<button (click)='end_callee_call(summarypoupup)' class="d-none" id="end_callee_call"></button>

<div id="butAcceptCall" class="butAcceptCall" style="display:none;">
  <h3>Incoming Video Call from <span id="caller_name"></span></h3>
  <p class="font-small-3 text-center cursor-pointer" (click)="acceptcall(callpopup)">Accept</p>
  <p class="font-small-3 text-center cursor-pointer" (click)="rejectCall()">Reject</p>
</div>
   <input type="hidden" value="" id="start_call_booking_id"/>
   <input type="hidden" value="" id="caller_name_value"/>
   <input type="hidden" value="{{timeLeft}}" id="calltimersecond"/>

   <audio id="myAudio">
    <source src="https://app.admitearly.com/apple_ring.mp3" type="audio/mpeg">
  </audio>
  <!---Receiver Start-->
  <ng-template #callpopup let-modal>
    <div class="reveivervideocall" id="videoCard">
      <div class="vsection" id="vsection">
        <video id="local_video_element_id" autoplay muted></video>
        <video id="remote_video_element_id" autoplay></video>
        <div class="d-flex justify-content-center" id="receiveCallPanel">
           <!--YOU HAVE A CALL LABEL-->
            <div style="display: none" id="isRinging" class="text-danger m-2">
               YOU HAVE A CALL!
            </div>
            <div class="calltimer">
              <span id="calltime" style="display:none">Call Time:</span><br/>
            <span id="timerstart">{{convertminutesec}}</span>
            </div>
          <!--ACCEPT CALL BUTTON-->
          <img style="display: none;width:40px;height:40px;cursor:pointer;" class="m-1" (click)="mutecall()" id="mutecall" src="assets/images/pages/mute.png"/>
          <img style="display: none;width:40px;height:40px;cursor:pointer;" class="m-1" (click)="unmutecall()" id="unmutecall" src="assets/images/pages/unmute.png"/>
          <img style="display: none;width:40px;height:40px;cursor:pointer;" class="m-1" (click)="stopvideo()" id="stopvideo" src="assets/images/pages/videostart.png"/>
        <img style="display: none;width:40px;height:40px;cursor:pointer;" class="m-1" (click)="startvideo()" id="startvideo" src="assets/images/pages/videostop.png"/>
          
        </div>
       </div>
       
       
      </div>
      <!--<button type="button" class="btn btn-primary d-none" id="endcallremote" (click)="endcallremote" rippleEffect>
        Accept
      </button>-->
      <button type="button" class="btn btn-primary d-none" id="endcallremote" (click)="modal.close('Accept click')" rippleEffect>
        Accept
      </button>
    
    </ng-template>

 <!---Receiver End-->
  <!----Call Summary Start--->
  <ng-template #summarypoupup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Call Summary</h4>
     
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
    <p>Thank for taking the call.</p>
    <p>Here is a summary of the call and your payout</p>
    <table class="w-100 mb-2">
      <tr>
        <td>Total Call Time:</td>
        <td align="right"><b>{{convertminutesec}}</b></td>
      </tr>
      <tr>
        <td>Total Earning:</td>
        <td align="right"><b>${{call_amout}}</b></td>
      </tr>
    </table>
    <p>Note: Your payout will be transferred within the next 24 hours</p>
    
    
  </div>

  <div class="modal-footer">
     
    <button type="button" class="btn btn-primary"rippleEffect (click)="call_complete_notification()">
      <span *ngIf="loadingsummary" class="spinner-border spinner-border-sm mr-1"></span> Done
    </button>
    <button type="button" class="btn btn-primary d-none" id="summaryclose" (click)="modal.close('Accept click')" rippleEffect>
    </button>
    
  
  </div>
  </ng-template>
  <!----Call Summary End----->
<div [@zoomIn]="zoomIn(outlet)" [@fadeInLeft]="fadeInLeft(outlet)" [@fadeIn]="fadeIn(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
