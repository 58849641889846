<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up" *ngIf="noticount != '0'">{{noticount}}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
  <li>
    <div  style="padding: 20px 15px 0;">
    <p class="media-heading"><b>Notification</b> 
      <span class="text-right float-right font-small-3 cursor-pointer" (click)="clear_notifications()" *ngIf="noticount != '0'">Clear</span></p>
    </div>
</li>
<li class="scrollable-container media-list text-center" *ngIf="noticount == '0'">
     
     
  <a class="d-flex" href="javascript:void(0)">
    <div class="media d-flex align-items-start">
     
      <div class="media-body">
        <p class="media-heading">No Notifications</p>
       
      </div>
    </div></a>
    
</li>
    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
     
     
      <a class="d-flex" href="javascript:void(0)" *ngFor="let notifications of notificationdata">
        <div class="media d-flex align-items-start">
         
          <div class="media-body">
            <p class="media-heading">{{notifications.title}}  <span class="text-right float-right font-small-3">{{notifications.formated_created_at}}</span></p>
            <small class="notification-text">{{ notifications.description }}</small>
          
          </div>
        </div></a>
        <div class="text-center mb-1">
        <a *ngIf="showLoadMore" class="btn btn-primary" href="javascript:void(0);" (click)="showMoreNotifications()"> <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Load More</a>
      </div>
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
   <!-- <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li>-->
    <!--/ Notifications footer -->
   
  </ul>
  
</li>
