<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="/assets/images/logo/weblogo.png" alt="brand-logo" width="100%" style="max-width: 150px;"/>
        </span>
        
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

   
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              >{{ this.currentUser.first_name }} {{ this.currentUser.last_name }}</span
            ><!--<span class="user-status">{{ this.currentUser.role }}</span>-->
          </div>
         
          <span class="avatar" >
            <img *ngIf = "this.currentUser.profile_image ==''" class="round" src="/assets/images/admitearly_icon.png" alt="{{ this.currentUser.first_name }} {{ this.currentUser.last_name }}" height="40" width="40" /><span class="avatar-status-online"></span>
            <img *ngIf = "this.currentUser.profile_image !=''"  class="round" src="{{ this.currentUser.profile_image }}" alt="{{ this.currentUser.first_name }} {{ this.currentUser.last_name }}" height="40" width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right" >
      
        
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
