import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

const usertype = localStorage.getItem('usertype');

export const menu: CoreMenu[] = [
  
  {
    id: 'school',
    title: 'Dashboard',
    //translate: 'MENU.APPS.EMAIL',
    type: 'item',
    role: ['Admin'],
    icon: 'mail',
    url: 'dashboard/school'

},

{
  id: 'college',
  title: 'Dashboard',
  //translate: 'MENU.APPS.EMAIL',
  type: 'item',
  role: ['Client'],
  icon: 'mail',
  url: 'dashboard/dashcollege'

},

  {
        id: 'suggestedconnection',
        title: 'Suggested Connections',
        type: 'item',
        role: ['Admin'],
        icon: 'mail',
        url: 'suggested/index'
  
  },
  {
    id: 'history',
    title: 'History',
    type: 'item',
    icon: 'copy',
    url: 'history'
  },
  {
    id: 'settingdrop',
    title: 'Settings',
    type: 'item',
    icon: 'settings',
    url: 'account-settings'
  },
  {
    id: 'slots',
    title: 'Update Availability',
    type: 'item',
    role: ['Client'],
    icon: 'clipboard',
    url: 'addslot'

},
  {
    id: 'terms',
    title: 'Terms & Conditions',
    type: 'item',
    icon: 'anchor',
    url: 'https://www.admitearly.com/terms-of-use/',
    externalUrl: true,
    openInNewTab: true
  },
  {
    id: 'policy',
    title: 'Privacy Policy',
    type: 'item',
    icon: 'align-center',
    url: 'https://www.admitearly.com/privacy-policy/',
    externalUrl: true,
    openInNewTab: true
  }
 
];
