import { Component, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { fadeInLeft, zoomIn, fadeIn } from '@core/animations/core.animation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from 'app/layout/components/content/content.service';
declare var sendbirdConnect: any;
@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInLeft, zoomIn, fadeIn]
})
export class ContentComponent {
  public coreConfig: any; 
  public animate;
  public currentUser;
  timeLeft: number = 0;
  interval;
  public hours; public minutes; public seconds; public convertminutesec;
  public call_amout; public call_duration;
  public loadingsummary = false;
  public videocallend =false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {NgbModal} modalService
   * @param {ContentService} _contentService
   */
  constructor(private _coreConfigService: CoreConfigService,private modalService: NgbModal,private _contentService: ContentService,) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * Fade In Left Animation
   *
   * @param outlet
   */
  fadeInLeft(outlet) {
    if (this.animate === 'fadeInLeft') {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Zoom In Animation
   *
   * @param outlet
   */
  zoomIn(outlet) {
    if (this.animate === 'zoomIn') {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Fade In Animation
   *
   * @param outlet
   */
  fadeIn(outlet) {
    if (this.animate === 'fadeIn') {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */

  conertsectominute(timeLeft)
  {
    const sec = parseInt(timeLeft, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    if (hours   < 10) {this.hours   = "0"+hours;}
    if (minutes < 10) {this.minutes = "0"+minutes;}
    if (seconds < 10) {this.seconds = "0"+seconds;}
    this.convertminutesec = minutes+'m '+seconds+'s';
  }

    /*startTimer() {
      this.interval = setInterval(() => {
        this.timeLeft++;
        this.conertsectominute(this.timeLeft);
       }, 1000);
    }*/

    pauseTimer() {
      clearInterval(this.interval);
    }
    callsummaryshow(summarypoupup)
    {
      this.modalService.open(summarypoupup, {
        beforeDismiss: () => {
          /*setTimeout(() => {
            alert('Please complete the call');
          }, 100);*/
          return false;
        },
        centered: true
      });
    }
    call_complete_notification()
    {
      this.loadingsummary = true;
      const booking_id = (<HTMLInputElement>document.getElementById("start_call_booking_id")).value;
      this._contentService.call_complete_notification(booking_id).subscribe(
        response => {
          this.loadingsummary = false;
          
           (<HTMLInputElement> document.querySelector('#summaryclose')).click();
           window.location.href="";
        });
    }
    end_callee_call(summarypoupup)
    {
      const duration = (<HTMLInputElement>document.getElementById("calltimersecond")).value;
      this._contentService.college_end_call(duration).subscribe(
        response => {
         
          this.call_amout = response.data.amount;
          this.call_duration = response.data.duration
          this.conertsectominute(this.call_duration);
          this.modalService.open(summarypoupup, {
            beforeDismiss: () => {
             /* setTimeout(() => {
                alert('Please complete the call');
              }, 100);*/
              return false;
            },
            centered: true
          });
        });
    }
   acceptcall(callpopup)
   {
    const booking_id = (<HTMLInputElement>document.getElementById("start_call_booking_id")).value;
    this._contentService.start_call(booking_id).subscribe(
      response => {
        this.modalService.open(callpopup, {
          beforeDismiss: () => {
            return false;
          },
          centered: true
        });
        sendbirdConnect.acceptCall();
        this.videocallend = true;
        this.interval = setInterval(() => {
          this.timeLeft++;
          this.conertsectominute(this.timeLeft);
         }, 1000);
      });

    
   }
   endcallremote()
   {
     this.videocallend = false;
   }
 
   endCall()
   {
     sendbirdConnect.endCall();
     this.modalService.dismissAll();
   }
   rejectCall()
   {
     sendbirdConnect.rejectCall();
   }
   mutecall()
   {
     sendbirdConnect.mutecall();
   }
   unmutecall()
   {
     sendbirdConnect.unmutecall();
   }
   stopvideo()
   {
     sendbirdConnect.stopvideo();
   }
   startvideo()
   {
    sendbirdConnect.startvideo();
   }
  ngOnInit(): void {
   
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser!=null)
    {
    sendbirdConnect.connect("D7D388B5-A9C6-44DE-85C5-472B5917E2A8",this.currentUser.id);
    //dev sendbirdConnect.connect("4CB85EC7-73A6-492F-A7A9-A10F377A2C30",this.currentUser.id);
    }
    /*else
    {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('usertype');
      localStorage.removeItem('logintoken');
      localStorage.removeItem('preference_data');
      localStorage.removeItem('booking_price');
    }*/
    // Subscribe config change D7D388B5-A9C6-44DE-85C5-472B5917E2A8
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.animate = this.coreConfig.layout.animation;
    });
  }
}
